// Toggle global options
$enable-negative-margins: true;

// Customize some defaults
$gray: #434b55;
$blue: #0068d8;
$info: #0068d8;
$green: #97cb4d;

//$primary: #97cb4d;
$primary: $green;

$link-decoration: none;

$input-btn-border-width: 1.5px;
$btn-border-radius: .5rem;

$gap: 15px;

$box-shadow-sm:
  0 0px 0.5px rgba(0, 0, 0, 0.031),
  0 0px 1.3px rgba(0, 0, 0, 0.044),
  0 0px 2.7px rgba(0, 0, 0, 0.056),
  0 0px 5.5px rgba(0, 0, 0, 0.069),
  0 0px 15px rgba(0, 0, 0, 0.1)
;

$box-shadow:
  0 0px 1px rgba(0, 0, 0, 0.031),
  0 0px 2.6px rgba(0, 0, 0, 0.044),
  0 0px 5.3px rgba(0, 0, 0, 0.056),
  0 0px 11px rgba(0, 0, 0, 0.069),
  0 0px 30px rgba(0, 0, 0, 0.1)
;

$box-shadow-lg:
  0 0px 3.1px rgba(0, 0, 0, 0.047),
  0 0px 7.8px rgba(0, 0, 0, 0.067),
  0 0px 15.9px rgba(0, 0, 0, 0.083),
  0 0px 32.9px rgba(0, 0, 0, 0.103),
  0 0px 90px rgba(0, 0, 0, 0.15)
;

// Load Bootstrap
@import "bootstrap/scss/bootstrap";

// CSS
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

// Header
header {
  background: linear-gradient(315deg, #a2acb9 0%, #e5eaf0 100%);

  @media (min-width: 576px) {
    min-height: 1000px;
  }

  .navigation {
    z-index: 120;
    top: 0;
    left: 0;
    width: 100%;

    @media (min-width: 576px) {
      position: absolute;
    }
  }

  .header-text {
    z-index: 100;
    height: 100%;

    @media (min-width: 576px) {
      min-height: 1000px;
      max-width: 380px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (min-width: 993px) {
      max-width: 450px;
    }

    @media (min-width: 1200px) {
      right: 500px;
    }
  }

  .screenshot-box {
    z-index: 1;
    top: 0;
    right: -50px;
    max-width: 50%;
    transform: rotate(-20deg);

    @media (max-width: 1399px) {
      right: -200px;
    }

    @media (max-width: 1199px) {
      right: -300px;
    }

    @media (max-width: 992px) {
      right: -500px;
    }

    @media (max-width: 767px) {
      right: -650px;
    }

    @media (max-width: 575px) {
      display: none;
    }
  }

  .screenshot {
    border-radius: .5rem;
    width: auto;
    height: 300px;
    box-shadow: $box-shadow-lg;
    position: absolute;

    // Line 1
    &.core-1-1 {
      top: -55px;
      right: -30px;
    }

    &.core-1-2 {
      top: -55px;
      right: calc(450px + -30px + 15px);
    }

    &.core-1-3 {
      top: -55px;
      left: calc(45px);
    }

    // Line 2
    &.core-2-1 {
      top: calc(-55px + 300px + 15px);
      right: 90px;
    }

    &.core-2-2 {
      top: calc(-55px + 300px + 15px);
      right: calc(450px + 90px + 15px);
    }

    &.core-2-3 {
      top: calc(-55px + 300px + 15px);
      left: calc(-90px + 15px);
    }

    // Line 3
    &.core-3-1 {
      top: calc(-55px + 300px + 15px + 300px + 15px);
      right: 40px;
    }

    &.core-3-2 {
      top: calc(-55px + 300px + 15px + 300px + 15px);
      right: calc(450px + 40px + 15px);
    }

    &.core-3-3 {
      top: calc(-55px + 300px + 15px + 300px + 15px);
      left: calc(20px - 45px);
    }
  }
}

.logo {
  width: 180px;
  height: auto;
}

// Cards
.card {
  border-radius: 1rem;
}

// Buttons
.btn {
  font-weight: 500;
  padding: .5rem 1.5rem;
}

// Utilities
.bg-green {
  background-color: lighten($green, 40);
}

.bg-gray {
  background-color: lighten($gray, 65);
}

.py-7 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
